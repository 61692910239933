html {
  margin: 0;
  overflow: auto;
  box-sizing: border-box;
  overscroll-behavior-x: none;
  font-size: 16px;
  background: #070D12;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  width: 100%;
}

*{
  scrollbar-width: thin;
  scrollbar-color: #3C5262 transparent;
}

*::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #3C5262;
    border-radius: 4px;
}

html,
body,
#root {
  height: 100%;
}

ol,
ul {
  padding: 0;
  margin: 0;
}