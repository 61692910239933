.liberty-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: url("../assets/images/Liberty-loader.gif") 50% 50% no-repeat
    rgb(0 0 0 / 32%);
}
.liberty-loader-div {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background: url("../assets/images/Liberty-loader.gif");
  width: 100%;
  height: 100vh;
}
.preparer-form {
  width: auto;
  height: 85vh;
}
.preparer-header {
  width: 80%;
  padding: 20px;
  font-size: 30px;
  color: aliceblue;
  font-family: LibreFranklin;
  display: flex;
  justify-content: space-between;
  background-color: #0f1e28;
}
.preparer-main {
  margin-top: 15px;
  width: 80%;
  background-color: #070d12;
}
