@font-face {
  font-family: "DMSans";
  src: url("DMSans.ttf");
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('SFProDisplay.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('Inter.ttf');
}

@font-face {
  font-family: 'LibreFranklin';
  src: url('LibreFranklin.ttf');
}